'use client';
import { useEffect } from 'react';
import styles from './Error.module.css';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  const contactDevelopers = () => {
    window.location.href =
      "mailto:contact@deagle.dev?subject=Erreur%20sur%20le%20site&body=Description%20de%20l'erreur:%0D%0A%0D%0A" + encodeURIComponent(error.message);
  };

  return (
    <div className={styles.errorContainer}>
      <h2 className={styles.errorMessage}>Quelque chose s&apos;est mal passé !</h2>
      <button className={styles.retryButton} onClick={() => reset()}>
        Réessayer
      </button>
      <button className={styles.contactButton} onClick={contactDevelopers}>
        Contacter les développeurs
      </button>
    </div>
  );
}
